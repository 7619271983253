<template>
  <div class="container">
    <LoadingContabilidade v-if="!usuario.Nome" :speed="1" :width="400" :height="auto"
                          style="margin-top: 25%;"></LoadingContabilidade>
    <div v-else-if="alerta.hidden">
      <div class="row">
        <div class="col-sm-12" style="margin-top: 25%;">
          <div class="alerta">
            {{ usuario.message }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row" style="margin-top: 10rem !important;">
        <div class="col-sm-7">
          <p class="titulo">
            Olá, <br>
          </p>
        </div>
        <div class="col-sm-7">

          <p class="titulo">
            você foi convidado pelo <span class="nome_usuario">{{ usuario.Nome }}</span> do CNPJ
            <span class="cnpj">{{ usuario.CNPJ }}</span>
            para fazer a autorização de emissão de notas de serviços eletrônicos na Easyanest.
          </p>

        </div>
      </div>
      <div class="row mt-lg-4 mb-lg-5">
        <div class="col-sm-12">
          <p class="frase">
            Precisamos que você crie uma conta ou faça o login para realizar o serviço de autorização
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-4">

          <router-link class="btn botao_criar_conta"
                       :to="{ path: '/contabilidade/novaconta/1/', query: { id: usuario.hash } }">
            Criar conta
          </router-link>
          <a class="botao_criar_conta_sem_btn" href="https://testanest.breathe.med.br/">
            Logar na Easyanest
          </a>

        </div>
      </div>
    </div>
  </div>
</template>
<style>
body {
  background-color: #FFFFFF !important;
}

.nome_usuario {
  color: #41B7FF;
}

.cnpj {
  color: #41B7FF;
}

.alerta {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */
  color: #242B30;
  text-align: center;
}


.frase {

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 148%;
  color: #7E7F80;
}


.botao_criar_conta_sem_btn {
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 112%;
  border-radius: 32px;
  color: #41B7FF;
  background-color: #FFFFFF;
  border: none;

}


.botao_criar_conta_150_lg {
  text-align: center;
  padding: 20px 25px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 40px;
  background: #3A95CD;
  color: #ffffff;
}

@media (max-width: 560px) {
  .frase {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 148%;
    /* identical to box height, or 24px */
    color: #7E7F80;
  }

  .botao_criar_conta_sem_btn {
    margin-top: 17px;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 112%;
    border-radius: 32px;
    color: #41B7FF;
    background-color: #FFFFFF;
    border: none;
  }

  .titulo {
    font-size: 24px;
    line-height: 32px;
  }

  .botao_criar_conta {

    display: grid;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 112%;
    border-radius: 32px;
    background: #41B7FF;
    color: #ffffff;
    border: none;

  }
}

</style>
<script>

import appConfig from "../../../../app.config";
import axios from 'axios';
import LoadingContabilidade from "@/components/loading/LoadingContabilidade.vue";


export default {

  components: {
    LoadingContabilidade
  },
  page: {
    title: "Compartilhamento",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {
      usuario: {
        Nome: null,
        status: false,
        message: null,
        hash: '',
        CNPJ: '',
      },
      alerta: {
        hidden: false
      }
    };
  },
  validations: {},
  computed: {},
  created() {

    try {

      localStorage.clear();

      let urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('h')) {

        this.verificaCadastro(urlParams.get('h'));

      } else {
        this.$router.push('./');
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async verificaCadastro(hash) {

      try {

        const result = await axios.post(process.env.VUE_APP_API + '/contabilidade', {
          hash: hash,
        });

        if (result.data.status && result.data.hash) {
          localStorage.setItem('hash_valido', result.data.hash);
          localStorage.setItem('info_usuario', JSON.stringify(result.data.usuario));
          this.usuario.Nome = result.data.usuario.Nome;
          this.usuario.CNPJ = this.formatCnpjCpf(result.data.usuario.CNPJ);
          this.usuario.hash = hash;

        } else {
          this.alerta.hidden = true;
          this.usuario.Nome = true;
          this.usuario.message = result.data.message;
        }

      } catch (e) {
        this.help_login = e.response.data.error;
      }
    },
    formatCnpjCpf(value) {
      const cnpjCpf = value.replace(/\D/g, '');

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");// eslint-disable-line
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");// eslint-disable-line
    }
  },
}
</script>
