<template>
  <div style="text-align:center;">
  <content-loader

                    viewBox="0 0 400 160"
                    :speed=this.speed
                    :width=this.width
                    :height=this.height
                    primaryColor="#ffffff"
                    secondaryColor="#e3e3e3"
  >
    <rect x="80" y="73" rx="3" ry="3" width="254" height="6" />
    <rect x="78" y="88" rx="3" ry="3" width="254" height="6" />
    <rect x="150" y="103" rx="3" ry="3" width="118" height="6" />
    <circle cx="210" cy="27" r="22" />
    <circle cx="181" cy="151" r="6" />
    <circle cx="211" cy="151" r="6" />
    <circle cx="241" cy="151" r="6" />
    <rect x="37" y="54" rx="32" ry="32" width="15" height="15" />
    <rect x="37" y="46" rx="0" ry="0" width="4" height="18" />
    <rect x="54" y="54" rx="32" ry="32" width="15" height="15" />
    <rect x="54" y="46" rx="0" ry="0" width="4" height="19" />
    <rect x="336" y="118" rx="32" ry="32" width="15" height="15" />
    <rect x="357" y="118" rx="32" ry="32" width="15" height="15" />
    <rect x="347" y="123" rx="0" ry="0" width="4" height="18" />
    <rect x="368" y="123" rx="0" ry="0" width="4" height="18" />
  </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: "LoadingContabilidade",
  components: {
    ContentLoader,
  },
  props: {
    width: {
      default: "400",

    },
    height : {
      default: "160",

    },
    speed: {
      default: 1
    }
  },
  data() {
    return {
      Dwidth: this.width ,
      Dheight: this.height ,
    }

  }
}
</script>

<style scoped>
content-loader {
  display: block;
  margin: auto;
}
</style>